import React, { useEffect, useState } from 'react'
import { RiArrowRightLine, RiArrowRightDownLine } from 'react-icons/ri'
import CoverBg from 'assets/coverbg.png'

import Bg0 from 'assets/home.jpg'
import Bg1 from 'assets/abox.png'
import Bg2 from 'assets/the9pc-new.jpeg'
import Bg3 from 'assets/ggac.jpg'
import AboxLogo from 'assets/aboxlogo.png'

import { HomeStyle } from './style'
import { Waypoint } from 'react-waypoint'
import { useNavigate } from 'react-router-dom'

const HomePage: React.FC = () => {
    const navigate = useNavigate()

    const [target, setTarget] = useState<number[] | []>([])
    const [currentEffect, setCurrentEffect] = useState('')


    useEffect(() => {
        if (navigator.language != 'zh-CN') {
            navigate('/home-en')
        }

        if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
            navigate('/mobile-home')
        }
    }, [])


    function in_array(search: number, array: Array<number>) {
        for (const i in array) {
            if (array[i] == search) {
                return true
            }
        }
        return false
    }
    const handleEnter = (id: number) => {
        console.log(id)

        setTarget([...target, id])

        setTimeout(() => {
            setCurrentEffect('animate__animated animate__fadeIn animate__slower show')
        }, 100)
    }

    const handleClick =(id:string)=>{
        document.getElementById(id)?.scrollIntoView({behavior:'smooth',block: "end"})
    }

    return (
        <HomeStyle>
            <div className='topfix'>
                <div className='left'></div>
            </div>
            <div className="container">
                <div className="left">
                    <div className="up">
                        上海点晴信息科技有限公司 <RiArrowRightDownLine />
                    </div>
                    <div className="down"></div>
                </div>
                <div className="right">
                    <div className="preview ">
                        <div className="bg" style={{ backgroundImage: `url(${CoverBg}` }}></div>
                        <ul className="menu">
                            <li onClick={()=>handleClick('1')}>
                            第九联盟
                            </li>

                            <li onClick={()=>handleClick('2')}>
                            GGAC
                            </li>

                            <li onClick={()=>handleClick('3')}>
                            美术外包
                            </li>
                            <li onClick={()=>handleClick('4')}>
                                ABOX
                            </li>
                        </ul>
                    </div>

                    <div className="preview" id="1">
                        <div className="bg" style={{ backgroundImage: `url(${Bg2}` }}></div>

                        <div className={`text ${in_array(1, target) && currentEffect}`}>
                            <Waypoint onEnter={() => handleEnter(1)}></Waypoint>
                            <div className="a">
                                <a href="http://9artedu.com" className="button">
                                    加入第九联盟 <RiArrowRightLine />
                                </a>
                            </div>

                            <div className="b">
                                <span>
                                    第九联盟是点晴科技旗下的一家专注CG数字艺术人才培训品牌，致力于打造线上、线下、岗前实训深度融合的创新教育生态，为第九艺术相关行业输送优秀技术人才。
                                    第九联盟师资均来自多年深耕游戏、动漫、影视、VR、AR、MR领域产品的制作的资深专家，我们以产品带动教研孵化多个艺术人才培养基地，为行业输送更多具有匠人精神的艺术家。
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="preview" id="2">
                        <div className="bg" style={{ backgroundImage: `url(${Bg3}` }}></div>
                        <Waypoint onEnter={() => handleEnter(2)}>
                            <div className={`text ${in_array(2, target) && currentEffect}`}>
                                <div className="a">
                                    <a href="https://www.ggac.com/v2/home" className="button">
                                        访问GGAC <RiArrowRightLine />
                                    </a>
                                </div>
                                <div className="b">
                                    <span>
                                        GGAC大赛(GGAC AWARDS)
                                        ,又名全球游戏动漫美术概念大赛，是全球顶级的游戏美术赛事，作为目前全球CG艺术界规模最大、奖金池最高的CG艺术顶级专业赛事，被誉为“CG艺术界的奥斯卡”。GGAC多年来致力于传统美学与现代CG的融合与创作，推动新时代中国文化艺术在世界舞台的展现与链接。是艺术家们追求CG界至高荣誉的殿堂。
                                    </span>
                                </div>
                            </div>
                        </Waypoint>
                    </div>

                    <div className="preview" id="3">
                        <div className="bg" style={{ backgroundImage: `url(${Bg0}` }}></div>

                        <Waypoint onEnter={() => handleEnter(3)}>
                            <div className={`text ${in_array(3, target) && currentEffect}`}>
                                <div className="a">
                                    {/* <a href="https://www.alchemyart.com.cn/home/zh-hans/" className="button">
                                        访问美术外包 <RiArrowRightLine />
                                    </a> */}
                                    <a href="https://www.alchemyart.com.cn/home" className="button">
                                        访问美术外包 <RiArrowRightLine />
                                    </a>
                                </div>
                                <div className="b">
                                    <p>
                                        点晴美术外包业务成立于2013年，秉承“服务至上、品质第一”的经营理念，点晴美术外包业务深受合作方赞誉，并成为国内外一线大厂商的首选供应商，也夯实了点晴美术外包业务在行业龙头地位。同时在这几年的努力下，点晴美术外包业务也从高品质的游戏、动画美术外包合作，拓展到了AR、VR美术内容制作，虚拟偶像、元宇宙数字资产制作及创意方案到制作量产落地的全方位服务商。
                                    </p>
                                </div>
                            </div>
                        </Waypoint>
                    </div>

                    <div className="preview" id="4">
                        <div className="bg" style={{ backgroundImage: `url(${Bg1}` }}></div>

                        <div className={`text-right ${in_array(4, target) && currentEffect}`}>
                            <Waypoint onEnter={() => handleEnter(4)}>
                                <img src={AboxLogo} alt="logo" style={{ width: '190px', marginBottom: '20px' }} />
                            </Waypoint>
                            <p>
                                美术盒子 ( ABOX ) 隶属上海点晴信息科技有限公司 我们致力于打造游戏行业安全、专业的美术外包平台
                                盒子团队成立于2015年，项目启动初期已获得由平安资本、丰厚 资本共同参与的首轮融资近1000万。美术盒子希望通过行业内资
                                源的聚合服务，改变传统作坊式游戏美术外发模式，为国内游戏 行业带来更加安全、开放、优质、高效的美术外包对接时代。
                            </p>
                            <div className="center">
                                <a href="https://www.9abox.com" className="button">
                                    加入ABOX <RiArrowRightLine />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer">
                <span>
                 <a href='https://beian.miit.gov.cn' style={{color:'#fff'}}> 沪ICP备15027479号-1 </a>   
                    
                    2016-2022 All Rights Reserved 上海点晴信息科技有限公司 版权所有 办公地址：上海市徐汇区宜山路1398号2幢2层209室 <a href="http://www.beian.gov.cn"  style={{color:'#fff'}}>沪公网安备：31010402009867号</a></span>
            </div>
        </HomeStyle>
    )
}

export default HomePage
