import React, { useEffect, useState } from 'react'
import { RiArrowRightLine, RiArrowRightDownLine } from 'react-icons/ri'
import CoverBg from 'assets/coverbg.png'

import Bg0 from 'assets/home.jpg'
import Bg1 from 'assets/abox.png'
import Bg2 from 'assets/the9bg.png'
import Bg3 from 'assets/ggac.jpg'
import AboxLogo from 'assets/aboxlogo.png'
import { HomeStyle } from './style'
import { Waypoint } from 'react-waypoint'
import Scroll from 'react-scroll'
import { useNavigate } from 'react-router-dom'

const HomePage: React.FC = () => {
    const navigate = useNavigate()
    const Element = Scroll.Element
    const scroller = Scroll.scroller
    const [target, setTarget] = useState<number[] | []>([])
    const [currentEffect, setCurrentEffect] = useState('')

    useEffect(() => {
        if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
            navigate('/mobile-home')
        }
    }, [])

    function in_array(search: number, array: Array<number>) {
        for (const i in array) {
            if (array[i] == search) {
                return true
            }
        }
        return false
    }
    const handleEnter = (id: number) => {
        console.log(id)

        setTarget([...target, id])

        setTimeout(() => {
            setCurrentEffect('animate__animated animate__fadeIn animate__slower show')
        }, 100)
    }

    const handleClick = (number: number) => {
        scroller.scrollTo('Slide' + number, {
            duration: 500,
            delay: 100,
            smooth: true
        })
    }

    return (
        <HomeStyle>
            <div className="container">
                <div className="left">
                    <div className="up">
                    ALCHEMY INFORMATION TECHNOLOGY Co.,Ltd. <RiArrowRightDownLine />
                    </div>
                    <div className="down"></div>
                </div>
                <div className="right">
                    <div className="preview ">
                        <div className="bg" style={{ backgroundImage: `url(${CoverBg}` }}></div>
                        <ul className="menu">
                            <li onClick={() => handleClick(1)}>Website</li>
                            <li onClick={() => handleClick(2)}>ABOX</li>
                            <li onClick={() => handleClick(4)}>GGAC</li>
                        </ul>
                    </div>
                    <Element name="Slide1">
                        <div className="preview ">
                            <div className="bg" style={{ backgroundImage: `url(${Bg0}` }}></div>

                            <Waypoint onEnter={() => handleEnter(1)}>
                                <div className={`text ${in_array(1, target) && currentEffect}`}>
                                    <div className="a">
                                        <a href="https://alchemyinfo.com/home" className="button">
                                            Visit Site <RiArrowRightLine />
                                        </a>
                                    </div>
                                    <div className="b">
                                        <p>
                                        ALCHEMY Information technology Co.,Ltd was established in 2013, focusing on game art and cinematic animation outsourcing. With talents specialize in 3D model, 2D concept, Animation, we worked with a lot of famous studios around the world and produced assets successfully for many AAA title Console games [ PS3, XBOX360 ], Mobile games, Platform games, Web games etc.. Styles included Korean/Japanese hand-painting, Western realistic, Toon shader etc.
                                        </p>
                                    </div>
                                </div>
                            </Waypoint>
                        </div>
                    </Element>
                    <Element name="Slide2">
                        <div className="preview">
                            <div className="bg" style={{ backgroundImage: `url(${Bg1}` }}></div>

                            <div className={`text-right ${in_array(2, target) && currentEffect}`}>
                                <Waypoint onEnter={() => handleEnter(2)}>
                                    <img src={AboxLogo} alt="logo" style={{ width: '190px', marginBottom: '20px' }} />
                                </Waypoint>
                                <p>
                                Abox is a subsidiary of Alchemy Shanghai Information Technology ltd., which dedicates to building a safe, professional and efficient classified information website for global art outsourcing industry. With initial capital and support from Pingan & Fenghou, Abox is striving to integrate various of resource, update the working process.  Abox is starting a revolution to create a new generation for game outsourcing industry which is more safe, transparent, qualified and efficiency !
                                </p>
                                <div className="center">
                                    <a href="https://www.9abox.com" className="button">
                                        Visit ABOX <RiArrowRightLine />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Element>
                    <Element name="Slide4">
                        <div className="preview">
                            <div className="bg" style={{ backgroundImage: `url(${Bg3}` }}></div>
                            <Waypoint onEnter={() => handleEnter(4)}>
                                <div className={`text ${in_array(4, target) && currentEffect}`}>
                                    <div className="a">
                                        <a href="https://www.ggac.com/v2/home" className="button">
                                            Visit GGAC <RiArrowRightLine />
                                        </a>
                                    </div>
                                    <div className="b">
                                        <span>
                                            
                                            GGAC Global Game Art Contest Since 2016, Chinese game industry has exceeded other areas and become the largest game
                                            market in the world. With an enormous number of game artists, everyone desires for a professional and authoritative
                                            industry benchmark. After the termination of Dominance War V, there is a vacancy for an global authoritative
                                            contest, which can expose the talents, can arouse the inspiration.
                                        </span>
                                        <span >
                                           
                                            after thorough thinking and discussion with the industry veterans, the ideal is became more and more clear --we need
                                            a crown for game art, which will be the game artists highest honor--GGAC! The goal is making the Global Game Art
                                            Contest [ the abbreviation GGAC ] to be the most prestigious contest, to provide a place to showcase the talents, to
                                            vitalize the industry, to be the artists carnival!
                                        </span>
                                    </div>
                                </div>
                            </Waypoint>
                        </div>
                    </Element>



                </div>
            </div>
            <div className="footer">
                <span>沪ICP备15027479号-1 Copyright  2016-2022 ALCHEMY Information technology Co.,Ltd. All Rights Reserved.</span>
            </div>
        </HomeStyle>
    )
}

export default HomePage
