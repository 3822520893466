import React from 'react'
import { HashRouter as Router, Route, Routes } from 'react-router-dom'
import HomePage from './pages/HomePage'
import HomePageEN from './pages/HomePageEn'
import MHomePageEN from './pages/MhomePageEn'
import MHomePage from './pages/MhomePage'
import NotFoundPage from './pages/NotFoundPage'
import { ROUTES } from './resources/routes-constants'
import 'animate.css';
import './styles/main.sass'


const RootComponent: React.FC = () => {
    return (
       
        <Router>
            <Routes>
                <Route path="*" element={<NotFoundPage />} />
                <Route path={ROUTES.HOMEPAGE_ROUTE} element={<HomePage />} />
                <Route path={ROUTES.MHOMEPAGE_ROUTE} element={<MHomePage />} />
                <Route path={ROUTES.HOMEPAGE_ROUTE_EN} element={<HomePageEN />} />
                <Route path={ROUTES.MHOMEPAGE_ROUTE_EN} element={<MHomePageEN />} />
            </Routes>
        </Router>

    )
}

export default RootComponent
