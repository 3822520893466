import React, { useEffect, useRef, useState } from 'react'
import { FooterStyle, MHomeStyle } from './mstyle'
import Bg0 from 'assets/home.jpg'
import Bg1 from 'assets/abox-m.png'

import Bg3 from 'assets/ggac-m.jpg'

import AboxLogo from 'assets/aboxlogo.png'
import CoverBg from 'assets/coverbg.png'
import Scroll from 'react-scroll'

import { RiArrowRightLine } from 'react-icons/ri'


const MhomePage: React.FC = () => {
    const scroller = Scroll.scroller;
    const Element  = Scroll.Element;

    const handleClick = (number:number) => {
        scroller.scrollTo('Slide'+number, {
            duration: 500,
            delay: 100,
            smooth: true,
          })
    }
    return (
        <div>
  <MHomeStyle>
            <div className="a">
                <span>ALCHEMY INFORMATION TECHNOLOGY Co.,Ltd.</span>
            </div>
            <div className="b">
            <section className='slide' style={{ backgroundImage: `url(${CoverBg}`,backgroundColor:"white" }}>
                    <div className='text'>
                    <ul className="menu">
                    <li onClick={()=>handleClick(1)}>Website</li>
                    <li onClick={()=>handleClick(4)}>ABOX</li>
                    <li onClick={()=>handleClick(2)}>GGAC</li>
                           
                            
                           
                          
                        </ul>
                    </div>
                </section>

                <Element name="Slide1">
                <section className='slide' style={{ backgroundImage: `url(${Bg0}` }}>
                    <div className='text'>
                        <div className='logo'></div>
                        <div className='desc' >
                        ALCHEMY Information technology Co.,Ltd was established in 2013, focusing on game art and cinematic animation outsourcing. With talents specialize in 3D model, 2D concept, Animation, we worked with a lot of famous studios around the world and produced assets successfully for many AAA title Console games [ PS3, XBOX360 ], Mobile games, Platform games, Web games etc.. Styles included Korean/Japanese hand-painting, Western realistic, Toon shader etc.
                        </div>
                        <div className='btn'>
                        <a href="https://alchemyinfo.com/home" className="button">
                        Visit <RiArrowRightLine />
                                    </a>
                        </div>
 
                    </div>
                </section>
                </Element>

 
                <Element name="Slide4">
                <section className='slide' style={{ backgroundImage: `url(${Bg1}` }}>
                    <div className='text'>
                        <div className='logo'>
                        <img src={AboxLogo} alt="logo" style={{ width: '190px',marginBottom:'20px'}} />
                        </div>
                        <div className='desc' style={{color:'black'}}>
                        Abox is a subsidiary of Alchemy Shanghai Information Technology ltd., which dedicates to building a safe, professional and efficient classified information website for global art outsourcing industry. With initial capital and support from Pingan & Fenghou, Abox is striving to integrate various of resource, update the working process.  Abox is starting a revolution to create a new generation for game outsourcing industry which is more safe, transparent, qualified and efficiency !
                        </div>
                        <div className='btn'>
                        <a href="https://www.9abox.com" className="button">
                        Visit ABOX <RiArrowRightLine />
                                    </a>
                        </div>
                    </div>
                </section>
                </Element>
                <Element name="Slide2">
                <section className='slide' style={{ backgroundImage: `url(${Bg3}` }}>
                    <div className='text'>
                        <div className='logo'></div>
                        <div className='desc'>
                        <span>
                                            
                                            GGAC Global Game Art Contest Since 2016, Chinese game industry has exceeded other areas and become the largest game
                                            market in the world. With an enormous number of game artists, everyone desires for a professional and authoritative
                                            industry benchmark. After the termination of Dominance War V, there is a vacancy for an global authoritative
                                            contest, which can expose the talents, can arouse the inspiration.
                                        </span>
                                        <span >
                                           
                                            after thorough thinking and discussion with the industry veterans, the ideal is became more and more clear --we need
                                            a crown for game art, which will be the game artists highest honor--GGAC! The goal is making the Global Game Art
                                            Contest [ the abbreviation GGAC ] to be the most prestigious contest, to provide a place to showcase the talents, to
                                            vitalize the industry, to be the artists carnival!
                                        </span>
                        </div>
                        <div className='btn'>
                        <a href="https://www.ggac.com/v2/home" className="button">
                        Visit GGAC <RiArrowRightLine />
                                    </a>
                        </div>
                    </div>
                </section>
                </Element>
            </div>
           
        </MHomeStyle>
        <FooterStyle>
        沪ICP备15027479号-1 Copyright  2016-2022 <br/>ALCHEMY Information technology Co.,Ltd. All Rights Reserved.
           </FooterStyle>
        </div>
      
    )
}

export default MhomePage
