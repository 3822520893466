import styled from 'styled-components'

export const MHomeStyle = styled.div`
    display: flex;
    background-color: black;
    .a {
        width: 30px;
        height: 100%;
        background-color: black;
        color: white;
        text-align: center;
        padding: 20px 0;
        span {
            writing-mode: vertical-rl;
        }
    }
    .b {
        flex: 1;
        width: 100%;

        .slide {
            height: 100vh;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            scroll-snap-align: start;
            scroll-snap-stop: always;
            .text {
                color: #fff;
                font-size: 12px;
                padding: 10px 10px;
                .logo {
                    display: flex;
                    justify-content: center;
                    margin: 20px 0;
                }
                .btn {
                    padding: 20px 0;
                    display: flex;
                    justify-content: center;
                    .button {
                        --background: #e62121;
                        --text: #fff;
                        --font-size: 16px;
                        --duration: 0.44s;
                        --move-hover: -4px;
                        --shadow: 0 2px 8px -1px #{rgba(#275efe, 0.32)};
                        --shadow-hover: 0 4px 20px -2px #{rgba(#275efe, 0.5)};
                        --font-shadow: var(--font-size);
                        padding: 16px 32px;
                        font-weight: 500;
                        line-height: var(--font-size);
                        border-radius: 24px;
                        display: block;
                        outline: none;
                        text-decoration: none;
                        font-size: var(--font-size);
                        letter-spacing: 0.5px;
                        background: var(--background);
                        color: var(--text);
                        box-shadow: var(--shadow);
                        transform: translateY(var(--y)) translateZ(0);
                        transition: transform var(--duration) ease, box-shadow var(--duration) ease;

                        width: 180px;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        div {
                            display: flex;
                            overflow: hidden;
                            text-shadow: 0 var(--font-shadow) 0 var(--text);
                            span {
                                display: block;
                                backface-visibility: hidden;
                                font-style: normal;
                                transition: transform var(--duration) ease;
                                transform: translateY(var(--m)) translateZ(0);
                                $i: 1;
                                @while $i < 12 {
                                    &:nth-child(#{$i}) {
                                        transition-delay: $i / 20 + s;
                                    }
                                    $i: $i + 1;
                                }
                            }
                        }
                        &:hover {
                            --y: var(--move-hover);
                            --shadow: var(--shadow-hover);
                            span {
                                --m: calc(var(--font-size) * -1);
                            }
                        }
                        &.reverse {
                            --font-shadow: calc(var(--font-size) * -1);
                            &:hover {
                                span {
                                    --m: calc(var(--font-size));
                                }
                            }
                        }
                    }
                }
                .menu {
                    display: flex;
                    list-style: none;
                    position: absolute;
                    bottom: 20px;
                    left: 50%;
                    transform: translate(-50%);
                    width: 100%;
                    justify-content: center;
                    li {
                        padding: 10px 15px;
                        font-size: 14px;
                        position: relative;
                        cursor: pointer;
                        color: #000;
                        &::before {
                            content: '';
                            display: block;
                            position: absolute;
                            height: 15px;
                            width: 1px;
                            background-color: #2f3545;
                            right: 0;
                            bottom: 13px;
                        }
                        &:last-child {
                            &::before {
                                content: '';
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
`
export const FooterStyle = styled.div`
    scroll-snap-align: start;
    scroll-snap-stop: always;
    color: #fff;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:0  10px;
    text-align: center;
    font-size: 12px;
`
