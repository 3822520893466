import styled from 'styled-components'

export const HomeStyle = styled.div`
  .topfix{
    height: 4px;
    width: 100%;
    display: flex;
    .left{
        width: 40px;
    background-color: #000;
    }
  }

.show{
    opacity: 1;
}
.menu{
    display: flex;
        list-style: none;
        position: absolute;
        bottom: 20px;
        left:50%;
        transform:translate(-50%);
        width: 100%;
        justify-content: center;
        li{
            padding: 10px 80px;
            font-size:18px;
            position: relative;
            cursor: pointer;
            &::before{
                content: "";
                display: block;
                position: absolute;
                height: 15px;
                width: 2px;
                background-color: #2f3545;
                right: 0;
                bottom:15px;
            }
            &:last-child{
                &::before{
                content: "";
                display: none;
            }
            }
            a{
                text-decoration: none;
                color: #121621;
            }
        }
         
}
       
        
.center{
    width: 100%;
    justify-content: flex-start;
    display: flex;
    margin: 30px 0;
}
    .button {
        &.dark {
            --background: #2f3545;
            --shadow: 0 2px 8px -1px #{rgba(#151924, 0.32)};
            --shadow-hover: 0 4px 20px -2px #{rgba(#151924, 0.5)};
        }
        &.white {
            --background: #fff;
            --text: #275efe;
            --shadow: 0 2px 8px -1px #{rgba(#121621, 0.04)};
            --shadow-hover: 0 4px 20px -2px #{rgba(#121621, 0.12)};
        }
        &.fast {
            --duration: 0.32s;
        }
    }

    .button {
        --background: #E62121;
        --text: #fff;
        --font-size: 16px;
        --duration: 0.44s;
        --move-hover: -4px;
        --shadow: 0 2px 8px -1px #{rgba(#275efe, 0.32)};
        --shadow-hover: 0 4px 20px -2px #{rgba(#275efe, 0.5)};
        --font-shadow: var(--font-size);
        padding: 16px 32px;
        font-weight: 500;
        line-height: var(--font-size);
        border-radius: 24px;
        display: block;
        outline: none;
        text-decoration: none;
        font-size: var(--font-size);
        letter-spacing: 0.5px;
        background: var(--background);
        color: var(--text);
        box-shadow: var(--shadow);
        transform: translateY(var(--y)) translateZ(0);
        transition: transform var(--duration) ease, box-shadow var(--duration) ease;
       
        width: 180px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        div {
            display: flex;
            overflow: hidden;
            text-shadow: 0 var(--font-shadow) 0 var(--text);
            span {
                display: block;
                backface-visibility: hidden;
                font-style: normal;
                transition: transform var(--duration) ease;
                transform: translateY(var(--m)) translateZ(0);
                $i: 1;
                @while $i < 12 {
                    &:nth-child(#{$i}) {
                        transition-delay: $i / 20 + s;
                    }
                    $i: $i + 1;
                }
            }
        }
        &:hover {
            --y: var(--move-hover);
            --shadow: var(--shadow-hover);
            span {
                --m: calc(var(--font-size) * -1);
            }
        }
        &.reverse {
            --font-shadow: calc(var(--font-size) * -1);
            &:hover {
                span {
                    --m: calc(var(--font-size));
                }
            }
        }
    }

    .footer{
        scroll-snap-align: start;
        scroll-snap-stop: always;
        height: 40px;
        color: #fff;
        background-color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
    }
`
